.sidebar{
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    width: 100%;
    height: 100%;
    float: right;
    overflow: hidden;
}

@media (max-width: 768px){
    .sidebar{
        border: none;
        width: 100%;
        height: 100%;
        float: right;
        overflow: hidden;
    }
}