  .beautiful-text {
      margin-top: 6em;
      font-family: 'Handwriting', Arial, sans-serif;
      font-size: 20px;
      color: rgb(92, 63, 105);
      text-align: center;
  }

  .categoryImage {
      margin: 0;
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: auto;
      cursor: pointer;
  }

  .categoryName {
      color: rgb(92, 63, 105) !important;
      margin-right: 1em;
      text-align: right;
  }

  .category-image-container{
      display: inline-block;
      width: 95%;
      padding: 1em;
      border: 2px solid rgb(130 90 147);
      text-align: center;
  }

  .road-category {
      margin-top: 20px;
      margin-left: 10px;
  }

  .home-category {
      margin-top: 40px;
      margin-left: 30px;
  }

  @media (max-width: 576px) {
      .beautiful-text {
          margin-top: 3em;
          margin-left: 2em;
      }
      .category-image-container{
        width: 85%;
      }
  }