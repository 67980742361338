.App {
  text-align: center;
}

.Body {
  max-width: 100% !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.head-line {
  margin-top: -20px;
  height: 1px;
  width: 95%;
  float: right;
  background-color: black;
}

.title {
  float: right;
  margin-right: 2em;
  margin-top: 2em;
  font-size: 20px;
}

.logo-image {
  cursor: pointer;
  width: 100px;
  height: auto;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}

.App-link {
  color: #61dafb;
}

.mainContainer {
  padding-top: 5em;
  overflow-x: hidden;
}

.mainContainerSinglePost {
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .mainContainer {
    padding-left: 1% !important;
  }
}