.createButtonContainer{
    padding-top: 1rem;
}
.postContainer {
    margin-left: 2.5%;
    margin-right: 2.5% !important;
    box-sizing: border-box;
  }

@media (max-width: 768px) {
    .side{
        margin-bottom: 1rem;
    }

    .postContainer{
        margin-left: 5%;
    }
}