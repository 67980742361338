.image-container {
    display: block;
    width: 95%;
    margin: 0 auto;
    padding: 1em;
    text-align: center;
    overflow: hidden; /* Ensures no content spills out */
    box-sizing: border-box;
}

.image {
    margin: 0 auto;
    display: block;
    width: 100%; /* Makes the image scale to fit the container */
    min-width: 5rem;
    height: auto; /* Maintains the aspect ratio */
    cursor: pointer;
    object-fit: contain; /* Keeps the full image visible within boundaries */
}

.about-title {
    margin-left: 0.8em;
    font-size: 20px;
}

.prose {
    max-width: 95%;
    margin: 0 auto;
    overflow-wrap: break-word;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .image-container {
        padding: 0; /* Remove padding */
        border: none; /* Remove border */
        width: 100%; /* Allow full width for the image */
    }

    .image {
        max-width: 100%; /* Ensure full width within container */
        height: auto; /* Maintain aspect ratio */
    }
}