.PostImage {
  width: 100%; /* Ensures the image scales with the card width */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures image fills the container */
}

/* Placeholder styling */
.placeholder-image {
  width: 100%;
  height: 300px; /* Adjust placeholder height */
  background-color: #f2f2f2;
}

/* Adjust image size for smaller screens */
@media (max-width: 768px) {
  .PostImage,
  .placeholder-image {
    height: auto; /* Allow height to adjust dynamically */
  }
}
